import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import NotificationService from "@/core/services/notification.service";
import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import { GET_ACCOUNTS } from "@/core/services/graphql/queries/dynamics365/account";
import * as pbi from "powerbi-client";
import dayjs from "dayjs";
import { GET_POWER_BI_REPORTS } from "@/core/services/graphql/queries/power_bi/report";
import { models } from "powerbi-client";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  data() {
    return {
      sentiaProviderOptions: [
        { display: "Sentia BE", value: "Sentia BE" },
        { display: "Sentia NL", value: "Sentia NL" }
      ],
      sentiaProviderSelectedItem: "",
      activeCustomers: true,
      selectedCustomer: {},
      skipLicenseReportQuery: true,
      hasEmbedUrl: false
    };
  },
  apollo: {
    accounts: {
      query: () => GET_ACCOUNTS,
      variables() {
        return {
          filters: {
            active: { value: this.activeCustomers },
            sen_provider: { value: this.sentiaProviderSelectedItem }
          }
        };
      },
      update(data) {
        return data.accounts;
      },
      skip() {
        return true;
      },
      watchLoading(isLoading) {
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      error(error) {
        NotificationService.error(error);
      }
    },
    license_report: {
      client: "connectClient",
      query: () => GET_POWER_BI_REPORTS,
      variables() {
        return {
          filters: {
            accountNumber: {
              value: [this.selectedCustomer.accountnumber]
            },
            reportName: {
              value: ["LICENSE_REPORT"]
            }
          }
        };
      },
      update(data) {
        this.skipLicenseReportQuery = true;
        const result = data.reports.powerBI.result;
        if (result) {
          this.hasEmbedUrl = true;
          this.renderPowerBIReport(result.embedUrl, result.embedToken);
        }
        return result;
      },
      skip() {
        return this.skipLicenseReportQuery;
      },
      watchLoading(isLoading) {
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      error(error) {
        NotificationService.error(error);
      }
    }
  },
  methods: {
    filterData(item, queryText) {
      return (
        item.name.toLowerCase().includes(queryText.toLowerCase()) ||
        item.accountnumber.toLowerCase().includes(queryText.toLowerCase())
      );
    },
    reloadAccounts() {
      if (this.sentiaProviderSelectedItem) {
        this.$apollo.queries.accounts.skip = false;
        this.$apollo.queries.accounts.refetch();
      }
    },
    loadData() {
      if (this.selectedCustomer) {
        this.skipLicenseReportQuery = false;
        this.$apollo.queries.license_report.refetch();
      }
    },
    renderPowerBIReport(embedUrl, embedToken) {
      const config = {
        tokenType: pbi.models.TokenType.Embed,
        type: "report",
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: false
        },
        embedUrl: embedUrl,
        accessToken: embedToken
      };

      const overviewFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "sg_int_CB_2 Customers",
          column: "Code"
        },
        operator: "In",
        values: [this.selectedCustomer.accountnumber]
      };

      const reportContainer = document.getElementById("reportContainer");
      const powerBI = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      powerBI.reset(reportContainer);
      const report = powerBI.embed(reportContainer, config);
      report.on("loaded", async function () {
        try {
          const pages = await report.getPages();
          pages.filter(function (page) {
            return page.isActive;
          })[0];

          await report.updateFilters(models.FiltersOperations.Replace, [
            overviewFilter
          ]);
        } catch (errors) {
          NotificationService.error(errors);
        }
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Group"
      },
      {
        title: "License Report"
      }
    ]);
  }
};
